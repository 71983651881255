import React from "react"
import styled from "styled-components"
import Jasmine from "../images/jasmine_cottage.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${Jasmine}");
  width: 100%;
  height: 400px;
  background-position: 20%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    height: 200px;
    background-size: auto 200px;
    background-repeat: no-repeat;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 180px;
  margin-bottom: 32px;
  background-size: 180px 30px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

class WhereToStayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Where to stay | Sara & Tim" />
        <H1>Where to stay</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <P>
          There is a good selection of different types of accommodation near our
          wedding venue for all budgets and tastes. As Upwaltham Barns is in the
          countryside, most of the options in the surrounding area are either
          pubs or B&B's. The closest hotels are in the coastal town of
          Chichester (around 15 minutes drive), where you are also more likely
          to find Airbnb rentals.
        </P>
        <P>
          Please let us know if you have any issues with finding suitable
          accommodation. With so many rescheduled weddings planned for this
          time, the earlier you book, the better. Given the current uncertain
          circumstances, we would suggest booking accomodation with a free
          cancellation policy.
        </P>
        <P>
          You can also check out{" "}
          <a href="https://upwalthambarns-weddings.co.uk/local-accommodation/">
            this list of suggestions
          </a>{" "}
          provided by the venue.
        </P>
      </Layout>
    )
  }
}

export default WhereToStayPage
